import { Outlet, Navigate } from 'react-router-dom';

import settings from '../config/settings.json';

import Navbar from './Navbar';
import Footer from './Footer';

const Layout = () => {
  if (settings.comingSoon) {
    return <Navigate to="/coming-soon" />
  }

  return (
    <div className=' text-textPrimary h-full'>
      <Navbar />

      <Outlet />

      <Footer />
    </div>
  );
};

export default Layout;
