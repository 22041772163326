import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Navbar = () => {
  return (
    <div className='absolute h-20 flex flex-row w-full items-center text-white p-3 px-6 justify-between text-xl'>
      <Link to='/'>
        <Logo className='w-80 h-20 fill-white' />
      </Link>

      <div className='flex flex-row gap-6'>
        <div className='cursor-pointer hover:text-gray-300'>
          <Link to='/'>Home</Link>
        </div>
        <div className='cursor-pointer hover:text-gray-300'>
          <Link to='/about-us'>About Us</Link>
        </div>
        <div className='cursor-pointer hover:text-gray-300'>
          <Link to='/gallery'>Gallery</Link>
        </div>
        <div className='cursor-pointer hover:text-gray-300'>
          <Link to='/contact-us'>Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
