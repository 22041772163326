import { Link } from 'react-router-dom';
import background from '../assets/footer.png';

const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${background})`
      }}
      className='w-full h-80 m-auto my-6 text-xl bg-cover bg-top bg-no-repeat flex justify-center items-center gap-8'
    >
      <div className='cursor-pointer hover:text-gray-300'>
        <Link to='/'>Home</Link>
      </div>
      |
      <div className='cursor-pointer hover:text-gray-300'>
        <Link to='/about-us'>About Us</Link>
      </div>
      |
      <div className='cursor-pointer hover:text-gray-300'>
        <Link to='/gallery'>Gallery</Link>
      </div>
      |
      <div className='cursor-pointer hover:text-gray-300'>
        <Link to='/contact-us'>Contact Us</Link>
      </div>
    </div>
  );
};

export default Footer;
