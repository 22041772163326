import background from '../assets/original4.jpeg';
import Banner from './Banner';

import banner from '../assets/7.jpg';
import readyToServe from '../assets/3.jpg';

const AboutUs = () => {
  return (
    <>
      <Banner background={background} isSmall title='About Us' />{' '}
      <div className='w-3/4 m-auto my-10 text-xl gap-10 flex flex-col'>
        <div className='flex flex-row justify-evenly gap-6'>
          <div className='w-1/2'>
            <img src={banner} alt='' className='rounded-xl' />
          </div>
          <div className='w-1/2'>
            <div className='text-5xl mb-10'>
              Your Best Value, Full Service Tamarack Marina
            </div>
            <hr className='my-5' />
            Tamarack Park Marina is a premium destination lifestyle marina
            offering members and guest boaters impeccable personalized service,
            first class marina amenities and superior benefits on Lake Simcoe.
            Tamarack Park Marina’s ultimate goal is to create a unique and
            memorable experience for boaters each time they dock by providing a
            modern family-friendly marina environment. Whether it be their
            extensive calendar of nautically inspired events, or their top notch
            Parts and Service departments, the Tamarack Park Marina crew aims to
            please with customized services and programs to satisfy their
            members’ needs. Tamarack Park Marina prides itself on being a luxury
            destination, a home away from home and a place where there are
            endless opportunities to escape from the ordinary.
          </div>
        </div>

        <div className='flex flex-row justify-evenly gap-6'>
          <div className='w-1/2'>
            <div className='text-5xl mb-10'>Ready to Serve You</div>
            <hr className='my-5' />
            At Bluffers Park Marina we tell boaters “relax, it’s all taken care
            of” and we mean it. If you want us to repair your boat, clean it,
            paint it, store it, fill it or put it in the water we can. If you
            require on-the-water repairs, we can do those. If you need a slip
            for a boat that is only 20 feet we can accommodate you. And if you
            want to stretch the boating season well into the fall, go ahead–our
            boat storage deadline is flexible.
          </div>
          <div className='w-1/2'>
            <img src={readyToServe} alt='' className='rounded-xl' />
          </div>
        </div>

        <div className='flex flex-col items-center gap-6'>
          <div className='text-5xl'>OUR MARINA MEMBERS SAY</div>
          <hr className='my-5 w-full' />
          <div className='text-center'>
            LORNE LEIBEL With the Marina for 8 years We wanted to let you know
            that we enjoyed our stay at Bluffers Park Marina last summer and to
            complement your staff, Michelle, Kelly, Lexy, Jenna and Jaime for
            being professional, courteous, knowledgeable and helpful to our
            wants and needs. It made our stay memorable, pleasant and hassle
            free. We are looking forward to another fishing holiday at the
            Marina this summer.
            <br />
            EDNA MCCALLION
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
